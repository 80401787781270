import api from "@/services/ApiService";

export default {
  getSupplierData(id) {
    return api().post(`/admin/manage_supplier/get_supplier_from_mitra`, id);
  },
  submitStatus(params) {
    return api().post(`/admin/manage_supplier/edit_supplier_list`, params);
  }
};
