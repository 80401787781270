<template>
  <div id="ManageSupplier">
    <v-container>
      <v-card class="secondary px-10 py-5">
        <v-card-title class="headline mb-5">Kelola Supplier</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="mitraSelect"
                :items="mitraList"
                item-text="store_name"
                item-value="store_id"
                label="Nama toko*"
                @change="getSupplier"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <div v-if="isFound">
        <v-card class="mt-10">
          <v-app-bar dense color="transparent">
            <v-toolbar-title>
              <h5>Daftar Supplier</h5>
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text class="pa-0">
            <template>
              <v-data-table
                :headers="headers"
                :items="supplierList"
                class="elevation-0"
                mobile-breakpoint="0"
              >
                <template slot="item" slot-scope="props">
                  <tr>
                    <td>{{ props.item.supplier_name }}</td>
                    <td>{{ props.item.supplier_code }}</td>
                    <td>{{ props.item.address_name }}</td>
                    <td>
                      <v-chip :color="getColor(props.item.flag_active)" dark>{{
                        props.item.flag_active === 1
                          ? "Terdaftar"
                          : "Tidak Terdaftar"
                      }}</v-chip>
                    </td>
                    <td>
                      <v-btn
                        color="success"
                        class="ma-2 white--text"
                        @click="editStatus(props.item)"
                        small
                      >
                        Ubah Status
                        <v-icon right small>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </template>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import CommonService from "@/services/CommonService";
import ManageSupplierService from "@/services/ManageSupplierService";

export default {
  data: () => ({
    mitraSelect: "",
    mitraList: [],
    supplierList: [],
    isFound: false,
    headers: [
      { text: "Nama Supplier", sortable: false },
      { text: "Kode Supplier", sortable: false },
      { text: "Alamat", sortable: false },
      { text: "Status", sortable: false },
      { text: "Aksi", sortable: false }
    ],
    methodColor: [null, "error", "success"]
  }),

  mounted() {
    this.getMitra();
  },

  methods: {
    getMitra() {
      CommonService.getMitra()
        .then(response => {
          this.mitraList = response.data.mitras;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    },
    getSupplier() {
      const payload = {
        store_id: this.mitraSelect
      };

      ManageSupplierService.getSupplierData(payload)
        .then(response => {
          this.isFound = true;
          this.supplierList = response.data.suppliers;
        })
        .catch(() => {
          this.isFound = false;

          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    },
    editStatus(data) {
      let flag = data.flag_active === 0 ? 1 : 0;

      const payload = {
        supplier_id: data.supplier_id,
        flag_active: flag,
        store_id: this.mitraSelect
      };

      ManageSupplierService.submitStatus(payload)
        .then(() => {
          this.$tostini({
            message: "Berhasil Diubah",
            type: "success"
          });
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.getSupplier();
        });
    },
    getColor(flag) {
      return this.methodColor[flag + 1];
    }
  }
};
</script>
